<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                Pitch an Idea
              </h2>
              <p>Module 5 Assignment</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h2 class="heading-title">Constructionist Learning</h2>
                      <div
                        class="description mt--30"
                        style="text-align: justify !important"
                      >
                        <v-col md="6" sm="6" cols="6" style="float: left">
                          <div class="thumbnail">
                            <img
                              class="w-100"
                              src="../../assets/images/inter.jpeg"
                              alt="Cody Squadroni"
                            />
                          </div>
                        </v-col>

                        <p style="text-align: justify !important;">
                          <strong>What is your great idea?</strong> <br />My idea is to create a capstone class that incorporates multiple disciplines from different departments that work together to create a real-world project.
                        </p>
                        <p>
                          <strong>Title</strong> <br />Interdisciplinary
                          Capstones in Higher Education
                        </p>
                        <p>
                          <strong>Intended Audience</strong> <br />
                          <ul>
                            <li>Students in Higher Education</li>
                            <li>Students from Different Departments and Disciplines</li>
                          </ul>
                        </p>
                        <p>
                          <strong>Explanation of what and how teaching and learning take place</strong><br>Interdiscplinary isn’t a new idea but I think we can approach it better at a higher level. The purpose of having an interdisciplinary capstone is to bring learners together from different backgrounds and disciplines to create a hands-on project. These learners will have experience in areas that would all work together and give them the knowledge of how real-world communication on a project would work and insight into other disciplines. In the end, they would construct more understanding of all the areas necessary for their area of interest. I will base this on higher education students in Computer Science, User Experience, Digital Media, English, and Foreign Languages. These are all different majors in different disciplines that could create something like a technology tool. Teaching in this situation would require a guide in each field to fill the gaps of information needed where the students can't construct the knowledge or have questions. These guides would develop a real-world project proposal and break it into phases that have an association with each student's discipline. The guides would observe the groups of students and engage only when the students seem to have a problem constructing and building their knowledge from each other. We would break each group to have a student in each specific area. 
                        </p>
                        <p>For example, the group could consist of a programmer (Computer Science), accessibility (User Experience), sales (Business), and content (Foreign Language, English, Communication). For the students to learn, they will be given a project proposal where everyone has an assigned piece of it with their discipline. Still, the significant part is they work together and construct knowledge of their group members to complete this project. They can learn from each other and see all the different pieces actually to create an app. Most students assume that application development involves just a programmer, but in fact, there are a lot of areas involved. Doing something like this brings a connection between colleges/departments but also gives students a real-world project where they would work with people they would if hired in their area.</p>
                        <p><strong>Identification of how your idea ties to constructionism</strong><br>Constructionism is seen here because we are putting different disciplines together to create a real-world project with minimal oversight and relying on their current knowledge and constructed knowledge to finish their project. They construct their knowledge from what they have already learned and gain more understanding from others in their group to help expand their skillset. This idea is a learn by doing but also see the importance of all the areas involved in what they are doing. After all, is said and done, they will have a finished project that they will be able to see and identify all the areas associated with each discipline. There is a lot to gain from this type of approach not just to construct their knowledge but it empowers students to explore different perspectives and views.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      items: [
        {
          thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `5 PPC Campaigns the majority.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Digital Marketing going to.`,
        },
        {
          id: 3,
          icon: "check",
          desc: ` Marketing Agency passage of.`,
        },
        {
          id: 4,
          icon: "check",
          desc: ` Seo Friendly you are going.`,
        },
      ],
      socialList: [
        {
          url: "https://www.facebook.com/",
          icon: "facebook",
        },
        {
          url: "https://www.linkedin.com/",
          icon: "linkedin",
        },
        {
          url: "https://instagram.com/",
          icon: "instagram",
        },
        {
          url: "https://twitter.com/",
          icon: "twitter",
        },
      ],
      moreProjects: [
        {
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
